export default {
  dummyImage: 'https://dummyimage.com/600x400/000000/ffffff&text=Image+Sample',
  translation: {
    home: {
      headerText: {
        candidate: 'header_text_candidate',
        hiringPartner: 'header_text_hiring_partner',
        signup: 'header_text_signup',
        login: 'header_text_login',
        register: 'header_text_register',
        contactUs: 'header_text_contact_us',
      },
      footerText: {
        candidate: 'footer_text_candidate',
        candidateMenus: [
          {
            key: 'footer_text_candidate_menu_1',
          },
          {
            key: 'footer_text_candidate_menu_2',
          },
          {
            key: 'footer_text_candidate_menu_3',
          },
          {
            key: 'footer_text_candidate_menu_4',
          },
        ],
        hiringPartner: 'footer_text_hiring_partner',
        hiringPartnerMenus: [
          {
            key: 'footer_text_hiring_partner_menu_1',
          },
          {
            key: 'footer_text_hiring_partner_menu_2',
          },
        ],
        company: 'footer_text_company',
        companyMenus: [
          {
            key: 'footer_text_company_menu_1',
          },
        ],
      },
    },
    general: {
      formCompanyEmailKey: 'form_company_email',
      formFullNameKey: 'form_fullname',
      formWhatsappNumberKey: 'form_wa',
      formPhoneNumberKey: 'form_phone_number',
      formEmailKey: 'form_email',
      formPasswordKey: 'form_password',
      formNewPasswordKey: 'form_new_password',
      formConfirmPasswordKey: 'form_confirm_password',
      formUniversityKey: 'form_university',
      formEnglishProficiencyKey: 'form_english_proficiency',
      formOrganizationExpKey: 'form_organization_exp_key',
      formYOEKey: 'form_yoe_key',
      formRoleKey: 'form_role_key',
      formJobRoleKey: 'form_job_role_key',
      formJobTitleKey: 'form_job_title_key',
      formJobSeekingStatusKey: 'form_job_seeking_status_key',
      formJobLocationKey: 'form_job_location_key',
      formRoleCategoryKey: 'form_role_category_key',
      formIndustryPrefKey: 'form_industry_pref_key',
      formSalaryRangeKey: 'form_salary_range_key',
      formSalaryDiscloseKey: 'form_salary_disclose_key',
      formCVKey: 'form_cv_key',
      formAdditionalFilesKey: 'form_additional_file_key',
      formHowDoYouKnowTGKey: 'form_how_do_u_know_tg_key',
      formMajorKey: 'form_major',
      formGPAKey: 'form_gpa',
      formGenderKey: 'form_gender',
      formCollegePeriodKey: 'form_college_period',
      formCompanyNameKey: 'form_company_name',
      formCompanyBenefitsKey: 'form_company_benefits',
      formCompanyCulturesKey: 'form_company_cultures',
      formCompanyWorkingTypesKey: 'form_company_working_types',
      formCompanyDescKey: 'form_company_desc',
      formCompanyAddressKey: 'form_company_address',
      formCompanyWebKey: 'form_company_web',
      formCompanyIndustryKey: 'form_company_industry',
      formCompanyLocationKey: 'form_company_location',
      formCompanyLogoKey: 'form_company_logo',
      formCompanyHeaderKey: 'form_company_header',
      formCompanySizeKey: 'form_company_size',
      formCompanyAgreementKey: 'form_company_agreement',
      formCompanyEmployStatusKey: 'form_company_employ_status',
      formJobDurationKey: 'form_job_duration_status',
      formJobResponsibilityKey: 'form_job_responsibility',
      formJobRequirementKey: 'form_job_requirement',
      formTechnicalSolutionKey: 'form_technical_solution',
      formCustomTechnicalTest: 'form_custom_technical_test',
      formLoginButtonKey: 'header_text_login',
      formSignUpButtonKey: 'header_text_signup',
      formForgotPasswordButtonKey: 'button_text_forgotpass',
      formOpenEmailButtonKey: 'button_text_openemail',
      formChangePasswordButtonKey: 'button_text_forgotpass',
      formContinueButtonKey: 'button_text_continue',
      formNextButtonKey: 'button_text_next',
      formBackButtonKey: 'button_text_back',
      formSaveButtonKey: 'button_text_save',
      formCancelButtonKey: 'button_text_cancel',
      formConfirmButtonKey: 'button_text_confirm',
    },
    login: {
      pageCandidateTitleKey: 'page_candidate_login_title',
      pageCandidateSubTitleKey: 'page_candidate_login_subtitle',
      pageCandidateContinueWithLabelKey: 'page_candidate_login_continue_with',
      pageHPTitleKey: 'page_hp_login_title',
      pageHPSubTitleKey: 'page_hp_login_subtitle',

      formErrorValidationMessageKey: 'page_login_error_validation',
      formSuccessValidationMessageKey: 'page_login_success',

      formSuccessSendEmailVerifyKey: 'page_email_verify_success',
    },
    basicInfo: {
      pageCandidateTitleKey: 'page_candidate_basicinfo_title',
      pageCandidateSubTitleKey: 'page_candidate_basicinfo_subtitle',
      pageCandidateWorkPreferenceTitleKey:
        'page_candidate_basicinfo_work_preference_title',
      pageCandidateWorkPreferenceSubTitleKey:
        'page_candidate_basicinfo_work_preference_subtitle',
      formErrorValidationMessageKey: 'page_basicinfo_error_validation',
      formErrorValidationStepTwoMessageKey:
        'page_basicinfo_error_validation_step_two',
      formSuccessConfirmActionKey: 'page_basicinfo_success_confirm',
    },
    searchJob: {
      formApplyButtonKey: 'button_text_apply',
      formApplyOnlyButtonKey: 'button_text_apply_only',
    },
    signup: {
      pageCandidateTitleKey: 'page_candidate_signup_title',
      pageHPTitleKey: 'page_hp_signup_title',
      pageHPSubtitleKey: 'page_hp_signup_subtitle',
      pageHiringPartnerTitleKey: 'page_hiring_partner_signup_title',
      pageHiringPartnerSubTitleKey: 'page_hiring_partner_signup_subtitle',
      pageCandidateSubTitleKey: 'page_candidate_signup_subtitle',
      pageCandidateContinueWithLabelKey: 'page_candidate_signup_continue_with',

      formErrorValidationMessageKey: 'page_signup_error_validation',
      formAfterSignUpMessageKey: 'page_signup_success',
    },
    forgotPass: {
      pageCandidateTitleKey: 'page_candidate_forgot_pass_title',
      pageCandidateSubTitleKey: 'page_candidate_forgot_pass_subtitle',
    },
    changePass: {
      pageCandidateTitleKey: 'page_candidate_change_pass_title',
      pageCandidateSubTitleKey: 'page_candidate_change_pass_subtitle',

      formErrorValidationMessageKey: 'page_changepass_error_validation',
      formSuccessValidationMessageKey: 'page_changepass_success',
      formReloginMessageKey: 'page_changepass_relogin_message',
    },
    resendPass: {
      pageCandidateTitleKey: 'page_candidate_resend_pass_title',
      pageCandidateSubTitleKey: 'page_candidate_resend_pass_subtitle',
    },
    resendPassSuccess: {
      pageCandidateTitleKey: 'page_candidate_resend_pass_success_title',
      pageCandidateSubTitleKey: 'page_candidate_resend_pass_success_subtitle',
    },
    profile: {
      formSuccessVerifyEmailKey: 'page_user_profile_verify_email_success',
      formSuccessSendResetPassKey: 'page_user_profile_reset_pass_success',
      formErrorValidationMessageKey:
        'page_user_profile_reset_pass_validation_failed',
      pageCandidateTitleKey: 'page_candidate_profile_title',
      pageCandidateSubTitleKey: 'page_candidate_profile_subtitle',
      pageCandidatePreferenceSubTitleKey:
        'page_candidate_profile_pref_subtitle',
      pageCandidateMyDocumentSubtitleKey:
        'page_candidate_profile_documents_subtitle',
      pageCandidateGeneralTestTitleKey:
        'page_candidate_basicinfo_general_test_title',
      pageCandidateCuratedJobs: 'page_candidate_curated_jobs_title',
      message: {
        updateProfileSuccess:
          'page_user_profile_message_profile_update_success',
        greeting: 'page_user_profile_message_greeting',
      },
    },
    notFound: {
      backToHomeHeaderKey: 'page_notfound_back_to_home_header_text',
      backToHomeKey: 'page_notfound_back_to_home_text',
    },
  },
};
