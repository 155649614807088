import { useEffect, useState } from 'react';
import { Row, Col, ButtonGroup, Button } from 'rsuite';

import './styles.scss';
import _ from 'lodash';
import moment from 'moment';

function PrivacyPolicyScreen(): JSX.Element {
  const [lang, setLang] = useState('en');

  const setENLang = (): void => setLang('en');
  const setIDLang = (): void => setLang('id');
  const year = moment().format('YYYY');

  useEffect(() => {
    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', 'white');
    }
  }, []);

  const renderENContent = (): JSX.Element => (
    <>
      <div className="paragraph-section">
        <h5>1. YOUR PERSONAL DATA</h5>
        <p>
          Based on the collection method, Your data is divided into:
          <br /> <br />
          (i) Data obtained from You
          <br />
          Is data provided by You when filing out electronic form or
          corresponding with Us, including but not limited to when You:
          <br />
          a. Create an account in Our Platform; <br />
          b. Subscribe to any kind of service in Our Platform; <br />
          c. Contacting Us, whether by phone, email, website, or other contact
          details stated in this Privacy Policy; <br />
          d. Filling out any survey or review about Training or Platform
          service(s);
          <br />
          e. Using Platform services, such as applying to job opportunities or
          any service that needs access to Your data.
          <br /> <br />
          Data that You submitted in those activities may include username,
          email, password, phone number, address, personal description, picture,
          educational background information, transaction detail, and other data
          related to the activities above.
          <br />
          (ii) Recorded data <br />
          Is data that will be collected by Us when You access the Platform,
          which including but not limited to: <br />
          a. Technical information, including IP Address, login information,
          type and version of the browser used, timezone, real-time geo
          location; and <br />
          b. Visitation info, including the time of your activities on the
          Platform, complete list of URLs visited, log or data records, product
          that You have seen or searched on the Platform, transaction data, and
          phone number or email address You used to contact Our team.
          <br />
          (iii) Obtained data from other sources
          <br />
          Is data collected from third parties, such as our partner who helps Us
          to develop Our service for You, the Educators listed on the Platform,
          financial service provider, and other public data source.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>2. USE OF DATA</h5>
        <p>
          We may use or combine the obtained data for the following purpose(s):
          <br />
          a. To do every reasonable effort to carry Our obligation to provide to
          provide You with any information, product, and services on our
          Platform;
          <br />
          b. To ensure that the Platform is presented in most effective way for
          You;
          <br />
          c. To manage, contact, and provide You with any assistance to resolve
          any inconvenience, transaction problem, or other difficulties during
          Your access or usage on Platform;
          <br />
          d. To improve Our Platform service(s);
          <br />
          e. To give recommendation or information about Workshop, competition,
          promotion, or any kind of information for You;
          <br />
          f. To monitor or investigate any transaction or other suspicious
          activities that allegedly violates Terms of Use or applicable laws;
          and
          <br />
          g. Other purposes which will be notified to You by Us and in
          accordance with applicable laws.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>3. DISCLOSURE OF YOUR PERSONAL DATA</h5>
        <p>
          We guarantee that there will be no loan, transfer, sale, or
          distribution of Your data to third parties without Your permission,
          unless requested by court or authorized legal institution in order to
          comply with legal obligation and applicable laws.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>4. DATA STORAGE AND DELETION</h5>
        <p>
          We will store the obtained data in a manner that is compliant with
          applicable laws. Every data that We store is encrypted and We will
          exert our reasonable efforts to protect the confidentiality of the
          data.
          <br />
          <br />
          All of Your personal data will be stored for:
          <br />
          a. As long as You are still a user of the Platform;
          <br />
          b. At least 5 (five) years since the date You stopped using Our
          Platform; or
          <br />
          c. In accordance with the original purpose(s) of data collection.{' '}
          <br />
          You can request the deletion of Your account and the data contained
          from Our system, by submitting a request of such deletion its reason,
          and a proof of identity such as identity card (KTP)/driving license
          (SIM)/passport that matches with the identity of such account at the
          Platform. We will retain Your anonymous transaction data for Our
          record.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>LIMITATION OF OUR RESPONSIBILITIES</h5>
        <p>
          You are responsible for the security of Your account, by maintaining
          the confidentiality of Your login information, such as email and
          password or other data that required to access Your account;
          <br />
          1. We are not responsible for any personal data leak that caused by
          You or third parties;
          <br />
          2. We are responsible for the protection and security of Your personal
          data in Our system, and we will exert any reasonable effort to prevent
          any failure of protecting it. If the protection fails, We will send a
          written notification to You within 14 (fourteen) calendar days as of
          the occurence of failure;
          <br />
          3. We are not responsible for validity, truthfulness, accuracy, or
          completeness of data that You submitted to Us;
          <br />
          4. If You are under 18 years old, We are not responsible for any
          submission of Your personal data, and recommend Your parent/legal
          guardian to monitor the submission of such data; and
          <br />
          5. We are not responsible for failure or delay in the performance of
          this Privacy Policy if such failure or delay caused by Force Majeure
          event. Force Majeure can be defined as any event without the fault or
          negligence of a Party which occurs beyond the reasonable control of
          such Party, where such Party could not have reasonably prevent or
          overcome it, including but not limited to natural disaster, fire, war,
          riot, demonstration, sabotage, system failures that caused by third
          parties, banking system failure, or any change of government&lsquo;s
          regulation and/or policy which may materially affect Our capacity to
          perform this Privacy Policy. If it happens, We will notify You in
          writing within 10 (ten) calendar days as of the occurrence of the
          Force Majeure event.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>COOKIES</h5>
        <p>
          When You access the Platform, We may put some cookies within Your
          browser. Cookies are small digital files stored on electronic device
          that You use to access the Platform. Your electronic device will
          automatically receives cookies when You visit or using the Platform
          service. However, You are able to modify or block cookies in Your
          browser settings.
          <br />
          We use cookies to record Your visit, operates the Platform, count
          visitation number, or any other purpose(s) related to Platform to
          Platform service(s) and in accordance with applicable laws.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>CHANGES TO PRIVACY POLICY</h5>
        <p>
          We may amend or update this Privacy Policy from time to time, in order
          to comply with Platform&lsquo;s developments and applicable laws. If
          We decide to amend or update this policy, We will put the “Last
          Updated Date” to Privacy Policy. We recommend You to regularly review
          this Privacy Policy, and with keep accessing and/or using the
          Platform, You further agree to any change or update of this Privacy
          Policy.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>Contact Us</h5>
        <p>
          If You have any question, please contact: <br />
          Email: partner@talentgrowth.id
          <br />
          Whatsapp: +62812-8209-3396
        </p>
      </div>
    </>
  );

  const renderIDContent = (): JSX.Element => (
    <>
      <div className="paragraph-section">
        <h5>1. DATA PRIBADI ANDA</h5>
        <p>
          Berdasarkan cara pengumpulannya, data Anda terbagi menjadi:
          <br /> <br />
          (i) Data yang diserahkan oleh Anda
          <br />
          merupakan data yang diberikan oleh Anda saat mengisi formulir
          elektronik maupun berkorespondensi dengan Kami, yang termasuk namun
          tidak terbatas pada data yang diserahkan saat Anda:
          <br />
          a. Membuat akun di Platform; <br />
          b. Berlangganan layanan pada Platform; <br />
          c. Menghubungi Kami, baik melalui telepon, email, situs, dan kontak
          lainnya yang tercantum pada Kebijakan Privasi ini; <br />
          d. Mengisi survey maupun penilaian terkait layanan Platform;
          <br />
          e. Mempergunakan layanan pada Platform, seperti aktivitas pencarian
          pekerjaan, training, atau aktivitas lainnya yang membutuhkan akses
          pada data Anda.
          <br /> <br />
          Data yang diserahkan oleh Anda dalam aktivitas tersebut dapat meliputi
          nama pengguna (username), email, kata sandi (password), nomor telepon,
          alamat, deskripsi pribadi, foto, informasi latar belakang pendidikan,
          detil transaksi, serta data-data lainnya yang terkait dengan aktivitas
          di atas.
          <br />
          (ii) Data yang terekam
          <br />
          merupakan data yang dapat dikumpulkan oleh Kami saat Anda mengakses
          Platform, yang termasuk namun tidak terbatas pada: <br />
          a. Informasi teknis, meliputi alamat protokol internet (IP Address),
          informasi log in, jenis dan versi browser yang digunakan, pengaturan
          zona waktu dan geo location secara real-time; <br />
          b. Informasi kunjungan, meliputi waktu dari setiap aktivitas Anda di
          Platform, daftar lengkap URL yang dikunjungi, data catatan/log,
          pekerjaan/training yang anda lihat atau cari pada Platform, data
          transaksi, serta nomor telepon maupun alamat email yang digunakan
          untuk menghubungi tim Kami.
          {/* <br /> <br />
          (iii) Obtained data from other sources Is data collected from third
          parties, such as our partner who helps Us to develop Our service for
          You, the Educators listed on the Platform, financial service provider,
          and other public data source. */}
        </p>
      </div>
      <div className="paragraph-section">
        <h5>2. PENGGUNAAN DATA</h5>
        <p>
          Kami dapat menggunakan maupun menggabungkan data-data yang diperoleh
          dengan cara di atas untuk tujuan berikut:
          <br />
          a. Melakukan segala upaya dalam rangka menjalankan kewajiban untuk
          menyediakan informasi, produk, jasa, dan layanan pada Platform kepada
          Anda;
          <br />
          b. Memastikan bahwa Platform disajikan dengan cara yang paling efektif
          bagi Anda;
          <br />
          c. Mengelola, menghubungi, dan menyediakan segala bantuan kepada Anda
          untuk menyelesaikan segala hambatan, atau kesulitan lainnya saat Anda
          mengakses maupun menggunakan Platform;
          <br />
          d. Meningkatkan layanan yang diberikan oleh Platform;
          <br />
          e. Memberi rekomendasi maupun informasi terkait kepada Anda;
          <br />
          f. Melakukan monitoring atau investigasi terhadap aktivitas lainnya
          yang mencurigakan dan terindikasi mengandung unsur pelanggaran
          terhadap Syarat Ketentuan atau peraturan perundang-undangan yang
          berlaku; dan
          <br />
          g. Tujuan lain yang disampaikan dari waktu ke waktu oleh Kami kepada
          Anda, yang diizinkan oleh hukum dan ketentuan perundang-undangan yang
          berlaku.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>3. PENGUNGKAPAN DATA PRIBADI</h5>
        <p>
          Kami menjamin tidak adanya peminjaman, pengalihan, penjualan, maupun
          distribusi data Anda kepada pihak ketiga tanpa seizin Anda, kecuali
          diminta oleh Pengadilan atau Lembaga hukum lainnya yang berwenang demi
          mematuhi kewajiban hukum dan peraturan perundang-undangan yang
          berlaku.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>4. PENYIMPANAN DAN PENGHAPUSAN DATA</h5>
        <p>
          Data pribadi Anda yang Kami terima akan Kami simpan dengan cara yang
          sesuai ketentuan hukum dan peraturan perundang-undangan yang berlaku.
          Semua data yang Kami simpan berbentuk data yang sudah dienkripsi dan
          Kami akan melakukan upaya yang wajar untuk melindungi kerahasiaan data
          tersebut.
          <br />
          <br />
          Seluruh data pribadi Anda akan kami simpan selama:
          <br />
          a. Anda masih menjadi pengguna Platform;
          <br />
          b. Setidaknya 5 (lima) tahun sejak tanggal Anda berhenti menggunakan
          Platform; atau
          <br />
          c. Sesuai dengan tujuan awal dikumpulkannya data pribadi tersebut.
          <br /> Anda dapat mengajukan penghapusan akun Anda di sistem Kami,
          dengan melampirkan surat permohonan penghapusan beserta alasannya, dan
          bukti diri yang sah berupa KTP/SIM/Paspor sesuai dengan identitas
          pribadi pada akun Platform. Kami akan menyimpan data transaksi Anda
          secara anonim untuk keperluan pembukuan.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>PEMBATASAN TANGGUNG JAWAB PLATFORM</h5>
        <p>
          1. Anda bertanggung jawab atas keamanan akun anda, dengan menjaga
          kerahasiaan informasi login berupa email dan kata sandi (password),
          maupun data lainnya yang digunakan untuk mengakses akun Anda;
          <br />
          2. Kami tidak bertanggung jawab atas kebocoran data pribadi yang
          disebabkan oleh Anda maupun pihak ketiga;
          <br />
          3. Kami bertanggung jawab atas perlindungan dan pengamanan data
          pribadi dalam sistem kami, dan melakukan segala upaya yang wajar untuk
          mencegah adanya kegagalan perlindungan data pribadi. Dan apabila
          terjadi kegagalan dalam perlindungan tersebut, maka kami akan
          memberitahukan secara tertulis mengenai kegagalan tersebut kepada Anda
          maksimal 14 (empat belas) hari kalender setelah kegagalan terjadi;
          <br />
          4. Kami tidak bertanggung jawab atas keabsahan, kebenaran, keakuratan,
          maupun kelengkapan data yang Anda berikan kepada Kami;
          <br />
          5. Apabila Anda berusia di bawah 18 tahun, Kami tidak bertanggung
          jawab atas input data pribadi Anda, dan menganjurkan agar pemberian
          data tersebut dipantau oleh orang tua/wali Anda; dan
          <br />
          6. Kami tidak bertanggung jawab atas kegagalan atau penundaan
          pelaksanaan kewajiban dalam Kebijakan Privasi ini apabila kegagalan
          atau penundaan tersebut disebabkan oleh Keadaan Kahar. <br /> Keadaan
          Kahar dapat didefinisikan sebagai segala kejadian yang tidak
          disebabkan oleh kesalahan atau kelalaian salah satu Pihak dan terjadi
          di luar kekuasaan Pihak tertentu, dimana salah satu Pihak tidak dapat
          mencegah atau mengatasi hal tersebut, termasuk namun tidak terbatas
          pada bencana alam, kebakaran, perang, kerusuhan, demonstrasi,
          sabotase, kegagalan sistem yang disebabkan oleh pihak ketiga,
          kegagalan sistem perbankan, atau segala perubahan pada peraturan
          perundang-undangan yang dikeluarkan oleh Pemerintah dan dapat
          mempengaruhi kapasitas Kami untuk menjalankan Kebijakan Privasi ini.
          Apabila Keadaan Kahar terjadi, Kami akan memberitahukannya secara
          tertulis kepada Anda maksimal 10 (sepuluh) hari kalender setelah
          Keadaan Kahar terjadi.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>COOKIES</h5>
        <p>
          Ketika Anda mengakses Platform, Kami dapat menempatkan sejumlah
          cookies pada browser Anda. Cookies adalah sebuah berkas digital kecil
          yang terdapat pada perangkat elektronik yang Anda gunakan untuk
          mengakses Platform. Perangkat elektronik Anda akan secara otomatis
          menerima cookies pada saat Anda mengunjungi atau menggunakan layanan
          pada Platform. Namun, Anda dapat memodifikasi maupun menolak
          penggunaan cookies melalui preferensi penggunaan browser Anda.
          <br /> <br />
          Cookies kami gunakan untuk mencatat kunjungan Anda, pengoperasian
          Platform, serta menghitung jumlah pengunjung Platform, dan alasan
          lainnya terkait layanan Platform yang sesuai hukum maupun peraturan
          perundang-undangan yang berlaku.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>PERUBAHAN KEBIJAKAN PRIVASI</h5>
        <p>
          Kami sewaktu-waktu dapat melakukan perubahan atau pembaruan tertentu
          terhadap Kebijakan Privasi ini, dalam rangka penyesuaian dengan
          perkembangan Platform maupun hukum yang berlaku. Jika terjadi
          perubahan atau pembaruan pada Kebijakan Privasi ini, Kami akan
          memberikan “Tanggal Pembaruan Terakhir” pada Kebijakan Privasi. Kami
          menghimbau Anda untuk melakukan peninjauan kepada Kebijakan Privasi
          ini secara berkala, dan dengan tetap mengakses dan/atau menggunakan
          Platform, Anda dianggap telah menyetujui segala perubahan maupun
          pembaruan Kebijakan Privasi.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>KONTAK TALENT GROWTH</h5>
        <p>
          Apabila terdapat pertanyaan, Anda dapat menghubungi: <br />
          Email: partner@talentgrowth.id
          <br />
          Whatsapp: +62812-8209-3396
        </p>
      </div>
    </>
  );

  return (
    <Row>
      <Col xs={24}>
        <div className="bg-title">
          <h1>
            Talent Growth Privacy & <br />
            Policy
          </h1>
        </div>

        <div className="paragraph-section">
          <ButtonGroup>
            <Button
              className={lang === 'en' ? 'active' : ''}
              onClick={setENLang}
            >
              English Ver.
            </Button>
            <Button
              className={lang === 'id' ? 'active' : ''}
              onClick={setIDLang}
            >
              Bahasa Indonesia Ver.
            </Button>
          </ButtonGroup>

          <h3>
            {lang === 'en' && 'Privacy Policy'}
            {lang === 'id' && 'Kebijakan Privasi'}
          </h3>
          {lang === 'en' && (
            <p>
              Last Updated Date: 1st January {year}
              <br /> <br />
              The following Privacy Policy (“Privacy Policy”) describes how
              Talent Growth (“We”) respect and protect every personal data owned
              by user or visitor (“You”) who access Our site
              (www.talentgrowth.id) or any other digital service(s) provided and
              managed by Us (“Platform”).
              <br /> <br />
              By using Platform, You (and/or Your parent or legal guardian if
              You are under 18 years old) agree that You have read and accepted
              this Privacy Policy which is an integral part of Our Terms and
              Conditions of Use (“Terms of Use”). You hereby ensure and
              guarantee that any data provided is valid and correct, and You
              have the right to provide Us with the data. If necessary and
              reasonably requested, You agree to provide any proof about the
              data validity and accuracy. In case of any change in data supplied
              to Us, You must update Your data by sending a request to Us. You
              also give permission for Us to collect, use, display, announce,
              disclose, process, open access, store, send, give, assign, manage,
              destroy, and protect (“Use”) your data for certain purpose(s) in
              this Privacy Policy and Terms of Use.
              <br />
              <br />
              By giving permission, You agree to release Your right for any
              claim, losses, dispute, or lawsuit that might occur over the Use
              of Your data in Our system.
              <br />
            </p>
          )}

          {lang === 'id' && (
            <p>
              Tanggal Pembaruan Terakhir: 1 Januari {year}
              <br /> <br />
              Pengaturan mengenai Kebijakan Privasi berikut ini (“Kebijakan
              Privasi”) menggambarkan bagaimana Talent Growth (“Kami”)
              menghargai serta melindungi data pribadi yang dimiliki oleh setiap
              pengguna atau pengunjung (“Anda”) yang mengakses situs Kami
              (www.talentgrowth.id) maupun layanan digital lain yang disediakan
              dan dikelola oleh Kami (“Platform”).
              <br /> <br />
              Dengan mengakses dan/atau menggunakan Platform, Anda (dan/atau
              orang tua/wali apabila Anda berusia di bawah 18 tahun) dianggap
              telah mengetahui serta menyetujui Kebijakan Privasi yang merupakan
              bagian tidak terpisahkan dari Syarat dan Ketentuan Penggunaan
              Platform (“Ketentuan Penggunaan”). Anda dengan ini memastikan dan
              menjamin bahwa setiap data yang diberikan adalah sah dan benar,
              dan Anda merupakan pihak yang memiliki hak atas pemberian data.
              Apabila dibutuhkan dengan alasan yang wajar, Anda setuju untuk
              memberikan bukti terkait keabsahan dan kebenaran data tersebut.
              Anda diwajibkan untuk mengajukan pembaruan data kepada Kami bila
              terjadi perubahan terhadap data yang Anda berikan sebelumnya. Anda
              juga memberikan persetujuan kepada Kami untuk memperoleh,
              menggunakan, menampilkan, mengumumkan, membuka, memroses, membuka
              akses, menyimpan, mengirim, memberikan, mengalihkan, mengelola,
              memusnahkan, serta melindungi (“Penggunaan”) data tersebut untuk
              keperluan tertentu sebagaimana diatur dalam Kebijakan Privasi dan
              Syarat Ketentuan Platform.
              <br />
              <br />
              Dengan memberikan persetujuan, Anda dianggap melepaskan hak atas
              klaim, kerugian, tuntutan, maupun gugatan yang mungkin terjadi
              atas Penggunaan data Anda dalam sistem Kami.
              <br />
            </p>
          )}
        </div>

        {lang === 'en' && renderENContent()}
        {lang === 'id' && renderIDContent()}
      </Col>
    </Row>
  );
}

export default PrivacyPolicyScreen;
